import { ProductType } from '../types/ProductTypes'
import { enrollSkus } from './constants'
import { Magento } from '../services/magento'

const buildEventCategories = () => ({
  type: {
    subCategories: {
      kids: { products: [] },
      'oral-sprays': { products: [] },
      nutritionals: { products: [] },
      hemp: { products: [] },
      topicals: { products: [] },
      'drink-mixes': { products: [] },
      collagen: { products: [] },
      protein: { products: [] },
      terpenes: { products: [] },
      swag: { products: [] },
      promotions: { products: [] },
      packs: { products: [] },
      events: { products: [] },
      'q-home-haven': { products: [], subCategories: {} },
      'sarah-spencer-collection': { products: [] },
      'new-arrivals-new': { products: [] },
      botaniq: { products: [] },
    },
    products: [],
  },
  benefit: {
    subCategories: {},
    products: [],
  },
  autoShipProducts: { products: [] },
  'q-home-single-products': { products: [] },
  specials: {
    subCategories: {
      '275special': {
        subCategories: {},
        products: [],
      },
      '215special': {
        subCategories: {},
        products: [],
      },
      '135special': {
        subCategories: {},
        products: [],
      },
    },
    products: [],
  },
})

const buildConfigProducts = (product: ProductType) => {
  let configProducts = []
  let defaultImage = product.image
  let configOptions = product.configurable_product_options_selection
  product.variants.forEach((variant, i) => {
    configProducts.push({
      ...variant.product,
      image: configOptions.media_gallery[i] ?? defaultImage,
    })
  })
  return configProducts
}

const buildCategories = (isRetail: boolean) => ({
  couponuse: { subCategories: {}, products: [] },
  type: {
    subCategories: {
      kids: { products: [] },
      'oral-sprays': { products: [] },
      nutritionals: { products: [] },
      hemp: { products: [] },
      topicals: { products: [] },
      'drink-mixes': { products: [] },
      collagen: { products: [] },
      protein: { products: [] },
      terpenes: { products: [] },
      swag: { products: [] },
      promotions: { products: [] },
      packs: { products: [] },
      events: { products: [] },
      'q-home-haven': { products: [], subCategories: {} },
      'sarah-spencer-collection': { products: [] },
      'new-arrivals-new': { products: [] },
      botaniq: { products: [] },
    },
    products: [],
  },
  benefit: {
    subCategories: {},
    products: [],
  },
  autoShipProducts: { products: [] },
  'q-home-single-products': { products: [] },
  specials: {
    subCategories: {
      '275special': {
        subCategories: {},
        products: [],
      },
      '215special': {
        subCategories: {},
        products: [],
      },
      '135special': {
        subCategories: {},
        products: [],
      },
    },
    products: [],
  },
})

export const buildStoreData = (
  productsArr: ProductType[],
  isEventSite: boolean,
  isRetail?: boolean
) => {
  let categories = {}

  if (isEventSite) {
    categories = buildEventCategories()
  } else {
    categories = buildCategories(isRetail)
  }

  productsArr?.forEach(product => {
    // build autoship products list
    if (product.autoship) {
      if (product.variants?.length > 0) {
        categories?.autoShipProducts.products.push(
          ...buildConfigProducts(product)
        )
      } else {
        categories?.autoShipProducts.products.push(product)
      }
    }

    product?.categories?.forEach((category, i) => {
      if (!!category.breadcrumbs) {
        const path = category.url_path.split('/')
        const parent = path[0]
        const child = path[1]

        if (parent === 'type') {
          if (child in categories[parent].subCategories) {
            categories[parent].subCategories[child].products.push(product)
            categories[parent].subCategories[child] = {
              ...categories[parent].subCategories[child],
              ...category,
            }
          } else {
            return
          }
        } else {
          if (child in categories[parent].subCategories) {
            categories[parent].subCategories[child].products.push(product)
          } else {
            categories[parent].subCategories[child] = {
              ...category,
              products: [product],
            }
          }
        }
      } else {
        const catKey = category.url_key

        if (catKey in categories) {
          categories[catKey].products.push(product)
          categories[catKey] = {
            ...categories[catKey],
            ...category,
          }
        } else {
          categories[catKey] = {
            ...category,
            products: [product],
            subCategories: [],
          }
        }
      }
    })
  })

  return categories
}

const { ambPremiumSku, ambBasicSku, ambRenewalPremiumSku, ambRenewalBasicSku } =
  enrollSkus

export const getAmbEnrollmentFees = async () => {
  const ambPremium = await Magento.Store.getSingleProductBySku({
    sku: ambPremiumSku,
  })
  const ambBasic = await Magento.Store.getSingleProductBySku({
    sku: ambBasicSku,
  })
  return { ambPremium, ambBasic }
}

export const getAmbRenewalFees = async () => {
  const ambRenewalPremium = await Magento.Store.getSingleProductBySku({
    sku: ambRenewalPremiumSku,
  })
  const ambRenewalBasic = await Magento.Store.getSingleProductBySku({
    sku: ambRenewalBasicSku,
  })
  return { ambRenewalPremium, ambRenewalBasic }
}

export const buildAutoshipStoreData = products =>
  products?.reduce((acc, curr) => ({ ...acc, [curr.sku]: curr }), {})

export const findStoreByLocale = (countryCode, markets) =>
  markets.find(market => market.country_code === countryCode)
