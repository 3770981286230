import React, { useState, useEffect } from 'react'
import { Button, Input, Label } from 'semantic-ui-react'
import { navigate } from 'gatsby'
// Components
import { CartProductCard, SearchMenu } from '../'
import WrongAutoshipModal from './WrongAutoshipModal'
// Context
import { useAuthContext } from '../../context/AuthContext'
import { useCartContext } from '../../context/CartContext'
import { usePrismic } from '../../context/PrismicContext'
// Styles
import { Container } from '../../styles/common'
import { AS } from './styled'
// Utils
import { formatCurrency } from '../../utils/i18n/stores'
import trash from '../../svg/TrashCanIcon.svg'

type ManageProps = {
  children?: React.ReactElement
  openDeleteModal: (arg0: boolean) => void
}

const Details = ({
  pvTotalText = '',
  reward = 0,
  rewardPointsEarnedText = '',
  showPv,
  hideShippingCost,
  total = 0,
  totalPv = 0,
  totalText = '',
  prismicCartPage,
}) => {
  const {
    langAndCountry,
    prismicData: {
      prismicGeneral: { msg_included },
    },
  } = usePrismic()
  const shippingPrice =
    total < 150
      ? prismicCartPage.first_tier_shipping[0].text
      : prismicCartPage.second_tier_shipping[0].text

  const shippingCost = total > 0 ? shippingPrice : 0

  const handlingCost =
    total > prismicCartPage.handling_price ? prismicCartPage.handling_price : 0

  return (
    <>
      <AS.DetailsRow>
        <span>{totalText}</span>
        <span>{formatCurrency(langAndCountry, total)}</span>
      </AS.DetailsRow>
      {showPv ? (
        <AS.DetailsRow>
          <span>{pvTotalText}</span>
          <span>{totalPv}</span>
        </AS.DetailsRow>
      ) : null}
      <AS.DetailsRow>
        <span>{prismicCartPage.shipping[0].text}</span>
        <span>
          {hideShippingCost
            ? msg_included
            : formatCurrency(langAndCountry, Number(shippingCost))}
        </span>
      </AS.DetailsRow>
      {prismicCartPage.handling_price > 0 && (
        <AS.DetailsRow>
          <span>{prismicCartPage.handling[0].text}</span>
          <span>{formatCurrency(langAndCountry, handlingCost)}</span>
        </AS.DetailsRow>
      )}
    </>
  )
}

const Manage = ({ children, openDeleteModal }: ManageProps) => {
  const {
    langAndCountry,
    prismicData: {
      prismicAutoshipPage: {
        next_autoship,
        pv_total: pvTotalText,
        reward_points_earned,
        total: totalText,
      },
      prismicCartPage,
    },
  } = usePrismic()
  const {
    autoShipItems,
    autoShipDate,
    autoShipData,
    handleSetAutoShipState,
    addToAutoShip,
    isAmbOfferInCart,
    isAmbPremiumOfferInCart,
    getTotalOfAutoShipItems,
  } = useCartContext()
  const { userType, qUser } = useAuthContext()
  const [isWrongAutoShipModalOpen, setIsWrongAutoShipModalOpen] =
    useState(false)

  const asCountryCode = autoShipData?.address?.countryCode.toUpperCase()
  const currentCountryCode = langAndCountry.substring(langAndCountry.length - 2)

  useEffect(() => {
    if (asCountryCode && asCountryCode !== currentCountryCode) {
      setIsWrongAutoShipModalOpen(true)
    }
  }, [asCountryCode, langAndCountry])

  const options = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  }

  const handleClearDate = () => handleSetAutoShipState({ autoShipDate: '' })
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          maxWidth: 700,
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <Input
          style={{
            marginLeft: 'auto',
          }}
          value={new Date(autoShipDate).toLocaleDateString('en-US', options)}
          onChange={handleClearDate}
          action
        >
          <Label
            style={{
              border: 'none',
            }}
            basic
          >
            {next_autoship[0].text}
          </Label>
          <input />
          <Button basic icon="calendar alternate" onClick={handleClearDate} />
        </Input>
        <AS.DeleteIcon
          style={{
            marginLeft: 'auto',
          }}
          src={trash}
          onClick={() => {
            openDeleteModal(true)
          }}
        ></AS.DeleteIcon>
      </div>

      <SearchMenu type="add" callback={addToAutoShip} />
      <Container padded>
        {autoShipItems?.map((item, i) => {
          return (
            <div key={`${item?.autoShipItemId ?? item.sku}-${i}`}>
              <CartProductCard item={item} useWholesale={true} />
            </div>
          )
        })}
        <Container padding="20px 0 0 0">
          <Details
            {...getTotalOfAutoShipItems()}
            totalText={totalText[0].text}
            pvTotalText={pvTotalText[0].text}
            rewardPointsEarnedText={reward_points_earned[0].text}
            showPv={userType === 'AMBASSADOR' || isAmbOfferInCart}
            hideShippingCost={
              isAmbPremiumOfferInCart ||
              qUser?.hasAmbassadorAutoshipFreeShipping ||
              qUser?.hasPreferredCustomerAutoShipFreeShipping
            }
            prismicCartPage={prismicCartPage}
          />
        </Container>
      </Container>
      <WrongAutoshipModal
        open={isWrongAutoShipModalOpen}
        onClose={() => {
          setIsWrongAutoShipModalOpen(false)
          navigate('/')
        }}
        currentCountryCode={currentCountryCode}
        asCountryCode={asCountryCode}
      />
      {children}
    </>
  )
}

Manage.displayName = 'AutoshipManage'

export default Manage
