import { gql } from 'graphql-request'

export const BASIC_INFO = gql`
  {
    customer {
      firstname
      lastname
      suffix
      date_of_birth
      email
      addresses {
        id
        firstname
        lastname
        street
        city
        region {
          region_code
          region
          region_id
        }
        postcode
        country_code
        telephone
        default_shipping
      }
    }
  }
`

export const CREATE_CUSTOMER = gql`
  mutation newCustomer(
    $firstname: String!
    $lastname: String!
    $email: String!
    $password: String!
  ) {
    createCustomer(
      input: {
        firstname: $firstname
        lastname: $lastname
        email: $email
        password: $password
      }
    ) {
      customer {
        email
      }
    }
  }
`

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($firstname: String, $lastname: String, $dob: String) {
    updateCustomerV2(
      input: { firstname: $firstname, lastname: $lastname, date_of_birth: $dob }
    ) {
      customer {
        email
        firstname
        lastname
        date_of_birth
      }
    }
  }
`

export const CHECK_IF_USER_EXISTS = gql`
  query userExists($email: String!) {
    isEmailAvailable(email: $email) {
      is_email_available
    }
  }
`

export const GET_FIREBASE_TOKEN = gql`
  mutation getFirebaseToken($email: String!, $password: String!) {
    getFireBaseToken(input: { email: $email, password: $password }) {
      status
      message
      firebase_token
    }
  }
`

export const GENERATE_CUSTOMER_TOKEN = gql`
  mutation getToken($email: String!, $password: String!) {
    generateCustomerToken(email: $email, password: $password) {
      token
    }
  }
`

export const GET_MAGENTO_TOKEN_FROM_FIREBASE_TOKEN = gql`
  mutation getCustomerToken(
    $jwt_token: String!
    $first_name: String!
    $last_name: String!
    $associate_id: String!
    $legacy_associate_id: String!
  ) {
    getCustomerToken(
      input: {
        jwt_token: $jwt_token
        first_name: $first_name
        last_name: $last_name
        associate_id: $associate_id
        legacy_associate_id: $legacy_associate_id
      }
    ) {
      status
      message
      customer_token
      isNewCustomer
    }
  }
`

// ====================== CUSTOMER ADDRESS ======================================

export const CREATE_CUSTOMER_ADDRESS = gql`
  mutation createCustomerAddress($input: CustomerAddressInput!) {
    createCustomerAddress(input: $input) {
      id
      region {
        region
        region_code
        region_id
      }
      country_code
      street
      telephone
      postcode
      city
      default_shipping
      default_billing
    }
  }
`

export const UPDATE_CUSTOMER_ADDRESS = gql`
  mutation updateCustomerAddress($id: Int!, $input: CustomerAddressInput!) {
    updateCustomerAddress(id: $id, input: $input) {
      id
      region {
        region
        region_code
        region_id
      }
      country_code
      street
      telephone
      postcode
      city
      default_shipping
      default_billing
    }
  }
`

export const DELETE_CUSTOMER_ADDRESS = gql`
  mutation deleteCustomerAddress($id: Int!) {
    deleteCustomerAddress(id: $id)
  }
`

// ====================== CUSTOMER ORDERS ======================================

// NOTE - this endpoint is deprecated
export const GET_CUSTOMER_ORDERS = gql`
  query {
    customerOrders {
      items {
        order_number
        id
        created_at
        grand_total
        status
      }
    }
  }
`

export const GET_ORDER = gql`
  query GetOrders($number: String) {
    customer {
      orders(filter: { number: { eq: $number } }) {
        items {
          # add items data...
          number
        }
      }
    }
  }
`

export const GET_ORDERS_PAGE_INFO = gql`
  query {
    customer {
      orders(pageSize: 3) {
        page_info {
          current_page
          page_size
          total_pages
        }
      }
    }
  }
`
export const GET_SINGLE_ORDER_INFORMATION = gql`
  query SingleOrder($orderNumber: String) {
    customer {
      orders(filter: { number: { eq: $orderNumber } }) {
        items {
          total {
            grand_total {
              value
            }
          }
          status
        }
      }
    }
  }
`

export const ORDER_HISTORY = gql`
  query OrderHistory($currentPage: Int) {
    customer {
      orders(pageSize: 3, currentPage: $currentPage) {
        page_info {
          current_page
          page_size
          total_pages
        }
        items {
          number
          id
          status
          order_date
          shipping_method
          payment_methods {
            name
            type
          }
          billing_address {
            street
            city
          }
          shipping_address {
            street
            city
          }
          shipments {
            comments {
              message
              timestamp
            }
            id
            items {
              id
              product_name
              product_sku
              quantity_shipped
              order_item {
                product_name
                product_sale_price {
                  value
                }
              }
            }
            number
            tracking {
              carrier
              number
            }
          }
          total {
            base_grand_total {
              value
            }
            discounts {
              amount {
                value
              }
              label
            }
            grand_total {
              value
              currency
            }
            shipping_handling {
              total_amount {
                value
                currency
              }
            }
            subtotal {
              value
            }
            taxes {
              amount {
                value
              }
              rate
              title
            }
            total_shipping {
              value
            }
            total_tax {
              value
            }
          }
          items {
            entered_options {
              label
              value
            }
            selected_options {
              label
              value
            }
            product_name
            product_sku
            product_url_key
            product_sale_price {
              value
              currency
            }
            quantity_ordered
            quantity_invoiced
            quantity_shipped
          }
          invoices {
            comments {
              message
              timestamp
            }
            id
            items {
              discounts {
                amount {
                  value
                }
              }
              id
              order_item {
                product_name
                product_sale_price {
                  value
                }
              }
              product_name
              product_sale_price {
                value
              }
              quantity_invoiced
            }
            number
            total {
              base_grand_total {
                value
              }
              grand_total {
                value
              }
              shipping_handling {
                total_amount {
                  value
                  currency
                }
              }
              subtotal {
                value
              }
              taxes {
                amount {
                  value
                }
                rate
                title
              }
              total_shipping {
                value
              }
              total_tax {
                value
              }
            }
          }
        }
      }
    }
  }
`
