export const enrollSkus = {
  ambPremiumSku: 'ERL001',
  ambBasicSku: 'ERL002',
  arq: 'E035',
  myQFit: 'E036',
  pcFee: 'PCFEE',
  pcSmartship: 'SMARTSHIP',
  ambRenewalPremiumSku: 'RNL001',
  ambRenewalBasicSku: 'RNL006',
}

export const geniusSkus = [
  'GeniusProMinusQMonthly',
  'GeniusBasicMonthlySku',
  'GeniusBasicMonthly',
  'GeniusBasicYearly',
  'GeniusProMinusQYearly',
  'GeniusProMonthly',
  'GeniusProYearly',
  'PK0GAIZ001',
]
