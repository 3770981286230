import React, { useState, useEffect, Fragment } from 'react'
import { navigate } from 'gatsby'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// Components
import {
  Seo,
  Primary as SubmitButton,
  Loading,
  StyledSelect,
  StyledInput,
  CountryDropdown,
} from '..'
import infoIcon from '../../svg/InfoIcon.svg'
import NextStepModal from './NextStepModal'
// Utils
import { buildEnrollmentSchema } from '../../utils/validations'
import { QService } from '../../services/q-services'
import { formatParagraph } from '../../utils/prismicUtils'
import {
  formatTaxDataFromPrismic,
  getTaxInputPlaceholder,
  getTaxInputWidth,
  findArrayItemsInObject,
} from '../../utils/i18n/enrollmentForm'
// Context
import { useAuthContext } from '../../context/AuthContext'
import { usePrismic } from '../../context/PrismicContext'
// Styles
import {
  Wrapper,
  Container,
  QLogo,
  PageHeader,
  PageComment,
  SectionTitle,
  StyledForm,
  ErrorLabel,
  SuccessLabel,
  StyledCheckbox,
  StyledDivider,
  StyledText,
  InfoIcon,
  ThemedPopup,
  SlugLoadingSpinner,
  Gap,
} from './EnrollmentForm.styles'

const EnrollmentForm = () => {
  const {
    qUser,
    userType,
    updateQUserFromAmbEnrollmentForm,
    manageReferral: { referralData },
    isAuthLoading,
    isAuthenticated,
  } = useAuthContext()

  const {
    activeCountries,
    storeCountryCode,
    langAndCountry,
    prismicData: {
      prismicCartPage,
      prismicAddUpdateShippingPage,
      prismicProfilePage,
      prismicAmbassadorEnroll,
    },
  } = usePrismic()

  const [identification, setIdentification] = useState('personal')
  const [addSpouseAsCoAmbassador, setAddSpouseAsCoAmbassador] = useState(false)
  const [includeBusinessPartner, setIncludeBusinessPartner] = useState(false)
  const [receiveText, setReceiveText] = useState(false)
  const initial1099State =
    prismicAmbassadorEnroll.hide_tax_fields === false ? true : false
  const [send1099FormElectronically, setSend1099FormElectronically] =
    useState(initial1099State)
  const [areTermsAccepted, setAreTermsAccepted] = useState(false)
  // Slug
  const [slug, setSlug] = useState('')
  const [loadingIsSlugAvailable, setLoadingIsSlugAvailable] = useState(false)
  const [isSlugAvailable, setIsSlugAvailable] = useState(false)
  const [showSlugAvailableMessage, setShowSlugAvailableMessage] =
    useState(false)
  // Popups
  const [ssnPopupOpen, setSsnPopupOpen] = useState(false)
  const [einPopupOpen, setEinPopupOpen] = useState(false)
  const [ten99PopupOpen, setTen99PopupOpen] = useState(false)
  // Submit
  const [loadingSubmission, setLoadingSubmission] = useState(false)
  const [submissionErrorMessage, setSubmissionErrorMessage] = useState(null)
  const [isNextStepModalOpen, setIsNextStepModalOpen] = useState(false)
  const [underAgeError, setUnderAgeError] = useState(false)

  useEffect(() => {
    if (isAuthLoading) return
    if (userType === 'AMBASSADOR') {
      alert(prismicAmbassadorEnroll.already_ambassador[0].text)
      localStorage.removeItem('enrollmentForm')
      navigate('/')
    }
    // if (!isAuthenticated && !isAuthLoading) navigate('/login')
  }, [isAuthLoading, isAuthenticated, userType])

  const langCountryArray = langAndCountry?.split('-')
  const parsedCountryCode = langCountryArray[1]

  const [selectedCountry, setSelectedCountry] = useState(null)

  useEffect(() => {
    const initCountry = activeCountries.find(
      country =>
        country.countryCode.toLowerCase() === parsedCountryCode.toLowerCase()
    )
    setSelectedCountry(initCountry)
  }, [activeCountries])

  const onCountryChange = e =>
    setSelectedCountry(
      activeCountries.find(
        country => country.countryCode === e.target.value.toLowerCase()
      )
    )

  const ssnFromPrismic = formatTaxDataFromPrismic(
    prismicAmbassadorEnroll.ssn_list
  )
  const einFromPrismic = formatTaxDataFromPrismic(
    prismicAmbassadorEnroll.ein_list
  )
  const initialSsnValues = ssnFromPrismic.map(({ name }) => ({ [name]: '' }))
  const initialEinValues = einFromPrismic.map(({ name }) => ({ [name]: '' }))
  // GET SAVED ENROLLMENT FORM DATA AND SET AS DEFAULT VALUES
  const savedValues = localStorage.getItem('enrollmentForm')
  const initialValues = {
    includeBusinessPartner: false,
    addSpouseAsCoAmbassador: false,
    address1: '',
    address2: '',
    areTermsAccepted: false,
    dateBirth: '',
    companyName: '',
    city: '',
    country: parsedCountryCode || storeCountryCode.toUpperCase(),
    emailAddress: qUser?.emailAddress,
    identification: 'personal',
    legalFirstName: qUser?.firstName,
    legalLastName: qUser?.lastName,
    zip: '',
    receiveText: false,
    send1099FormElectronically: initial1099State,
    slug: '',
    state: '',
    primaryPhoneNumber: '',
    ...Object.assign({}, ...initialSsnValues),
    ...Object.assign({}, ...initialEinValues),
  }
  const defaultFormValues = savedValues
    ? JSON.parse(savedValues)
    : initialValues

  useEffect(() => {
    if (!savedValues) return
    const data = JSON.parse(savedValues)
    setIdentification(data?.identification)
    setAddSpouseAsCoAmbassador(data?.addSpouseAsCoAmbassador)
    setIncludeBusinessPartner(data?.includeBusinessPartner)
    setReceiveText(data?.receiveText)
    setSend1099FormElectronically(data?.send1099FormElectronically)
    setAreTermsAccepted(data?.areTermsAccepted)
    setSlug(data?.slug)
  }, [savedValues])

  const {
    register,
    setFocus,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(buildEnrollmentSchema(prismicAmbassadorEnroll)),
    mode: 'onBlur',
    defaultValues: defaultFormValues,
  })
  const handleTaxInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const valueLength = event.target.value.length
    ssnFromPrismic.map(({ name, length }, index) => {
      // set focus on next ssn field if there is another field
      if (
        fieldName === name &&
        valueLength === length &&
        ssnFromPrismic.length !== index + 1
      ) {
        setFocus(ssnFromPrismic[index + 1].name)
      }
      // on last ssn input, set focus on first name
      if (
        fieldName === name &&
        valueLength === length &&
        ssnFromPrismic.length === index + 1
      ) {
        setFocus('legalFirstName')
      }
    })
    einFromPrismic.map(({ name, length }, index) => {
      // set focus on next ein field if there is another field
      if (
        fieldName === name &&
        valueLength === length &&
        einFromPrismic.length !== index + 1
      ) {
        setFocus(einFromPrismic[index + 1].name)
      }
      // on last ein input, set focus on company name
      if (
        fieldName === name &&
        valueLength === length &&
        einFromPrismic.length === index + 1
      ) {
        setFocus('companyName')
      }
    })
  }

  const validateSlug = async () => {
    if (slug.length < 3) return
    setLoadingIsSlugAvailable(true)
    try {
      const slugData = await QService.User.isSlugAvailable({
        slug,
      })
      setIsSlugAvailable(slugData)
    } catch (error) {
      console.log('error', error)
    }
    setLoadingIsSlugAvailable(false)
    setShowSlugAvailableMessage(true)
  }

  const getAge = birthDate =>
    Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10)

  const onSubmit = async (data): Promise<void> => {
    if (!isSlugAvailable) return setShowSlugAvailableMessage(true)
    if (getAge(data.dateBirth) < 18) return setUnderAgeError(true)
    setLoadingSubmission(true)
    setSubmissionErrorMessage(null)
    const {
      address1,
      address2,
      city,
      state,
      zip,
      country,
      addSpouseAsCoAmbassador,
      includeBusinessPartner,
      identification,
      ...rest
    } = data
    const ssnString = findArrayItemsInObject(ssnFromPrismic, data).join('')
    const einString = findArrayItemsInObject(einFromPrismic, data).join('')
    const variables = {
      ...qUser,
      ...rest,
      address: {
        address1,
        address2,
        city,
        state,
        zip,
        countryCode: country,
      },
      receiveEmail: true,
      receiveText,
      socialSecurityNumber: identification === 'personal' ? ssnString : null,
      employerIdentificationNumber:
        identification === 'business' ? einString : null,
      includeBusinessPartner:
        identification === 'personal'
          ? addSpouseAsCoAmbassador
          : includeBusinessPartner,
    }
    await updateQUserFromAmbEnrollmentForm(variables)
      .then(() => {
        localStorage.removeItem('enrollmentForm')
        setIsNextStepModalOpen(true)
        setSubmissionErrorMessage(null)
      })
      .catch(error =>
        setSubmissionErrorMessage(
          `${prismicAmbassadorEnroll.sorry_error[0].text}: ${error.response.errors[0].message}`
        )
      )
    setLoadingSubmission(false)
  }

  const handleSaveDataOnBlur = () => {
    let currentValues = getValues()
    ssnFromPrismic.map(item => delete currentValues[item.name])
    einFromPrismic.map(item => delete currentValues[item.name])
    localStorage.setItem('enrollmentForm', JSON.stringify(currentValues))
  }

  // SHOW LOADING SCREEN IF AUTHENTICATION IS LOADING
  if (isAuthLoading)
    return (
      <Loading
        loading={isAuthLoading}
        message={prismicAmbassadorEnroll.loading[0].text}
      />
    )

  return (
    <>
      <Seo title={prismicAmbassadorEnroll.enrollment[0].text} />
      <Wrapper>
        <Container>
          <QLogo src="https://shopq.cdn.prismic.io/shopq/06ea17c4-0c65-4563-9507-bc314540e05f_black-q-sciences.svg" />
        </Container>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <PageHeader>
            {prismicAmbassadorEnroll.ambassador_enrollment[0].text}
          </PageHeader>
          {referralData?.ambassadorName && (
            <PageComment>{`(${prismicAmbassadorEnroll.referred_by[0].text} ${referralData?.ambassadorName})`}</PageComment>
          )}
          {prismicAmbassadorEnroll.hide_tax_fields === false ? (
            <>
              <SectionTitle>
                {prismicAmbassadorEnroll.tax_identification[0].text}
              </SectionTitle>
              <Container margin="20px 0 0;">
                {errors.identification && (
                  <ErrorLabel>{errors.identification?.message}</ErrorLabel>
                )}
              </Container>
              <Container>
                <Container justify="flex-start" direction="row">
                  <StyledCheckbox
                    style={{ fontSize: '18px' }}
                    radio
                    name="identificationGroup"
                    label={
                      prismicAmbassadorEnroll.individual_personal__ssn_[0].text
                    }
                    value="personal"
                    checked={identification === 'personal'}
                    {...register('identification')}
                    onChange={() => {
                      setIdentification('personal')
                      setIncludeBusinessPartner(false)
                      setValue('identification', 'personal')
                      setValue('includeBusinessPartner', false)
                      clearErrors(einFromPrismic.map(({ name }) => name))
                      handleSaveDataOnBlur()
                    }}
                  />
                  <ThemedPopup
                    content={prismicAmbassadorEnroll.ssn_popup[0].text}
                    on="click"
                    onClose={() => setSsnPopupOpen(false)}
                    onOpen={() => setSsnPopupOpen(true)}
                    open={ssnPopupOpen}
                    position="bottom right"
                    hideOnScroll={true}
                    trigger={<InfoIcon src={infoIcon} />}
                  />
                </Container>
              </Container>

              <Container margin="0 0 0 40px;">
                <Container margin="10px 0 0;">
                  {ssnFromPrismic.map(({ name }) => (
                    <Fragment key={name}>
                      {errors[name] && (
                        <ErrorLabel>{errors[name].message}</ErrorLabel>
                      )}
                    </Fragment>
                  ))}
                </Container>
                <Container
                  direction="row"
                  align="flex-end"
                  justify="flex-start"
                >
                  {ssnFromPrismic.map(({ name, length }, index) => (
                    <Fragment key={name}>
                      <StyledInput
                        {...register(name)}
                        placeholder={getTaxInputPlaceholder(length)}
                        maxLength={length}
                        width={getTaxInputWidth(length)}
                        onChange={event => handleTaxInput(event, name)}
                        disabled={identification === 'business'}
                        defaultValue={''}
                      />
                      {ssnFromPrismic.length !== index + 1 && <StyledDivider />}
                    </Fragment>
                  ))}
                </Container>

                <Container>
                  <StyledCheckbox
                    {...register('addSpouseAsCoAmbassador')}
                    label={prismicAmbassadorEnroll.include_spouse[0].text}
                    value="addSpouseAsCoAmbassador"
                    checked={addSpouseAsCoAmbassador === true}
                    onChange={() => {
                      setAddSpouseAsCoAmbassador(prev => {
                        setValue('addSpouseAsCoAmbassador', !prev)
                        return !prev
                      })
                    }}
                    disabled={identification === 'business'}
                    onBlur={handleSaveDataOnBlur}
                  />
                </Container>
              </Container>

              <Container margin="10px 0 0;">
                <Container justify="flex-start" direction="row">
                  <StyledCheckbox
                    style={{ fontSize: '18px' }}
                    radio
                    name="identificationGroup"
                    label={prismicAmbassadorEnroll.business_llc__ein_[0].text}
                    value="business"
                    checked={identification === 'business'}
                    {...register('identification')}
                    onChange={() => {
                      setIdentification('business')
                      setAddSpouseAsCoAmbassador(false)
                      setValue('identification', 'business')
                      setValue('addSpouseAsCoAmbassador', false)
                      clearErrors(ssnFromPrismic.map(({ name }) => name))
                      handleSaveDataOnBlur()
                    }}
                  />
                  <ThemedPopup
                    content={prismicAmbassadorEnroll.llc_popup[0].text}
                    on="click"
                    onClose={() => setEinPopupOpen(false)}
                    onOpen={() => setEinPopupOpen(true)}
                    open={einPopupOpen}
                    position="bottom center"
                    hideOnScroll={true}
                    trigger={<InfoIcon src={infoIcon} />}
                  />
                </Container>
              </Container>

              <Container margin="0 0 0 40px;">
                <Container margin="10px 0 0;">
                  {einFromPrismic.map(({ name }) => (
                    <Fragment key={name}>
                      {errors[name] && (
                        <ErrorLabel>{errors[name].message}</ErrorLabel>
                      )}
                    </Fragment>
                  ))}
                </Container>
                <Container
                  direction="row"
                  align="flex-end"
                  justify="flex-start"
                >
                  {einFromPrismic.map(({ name, length }, index) => (
                    <Fragment key={name}>
                      <StyledInput
                        {...register(name)}
                        placeholder={getTaxInputPlaceholder(length)}
                        maxLength={length}
                        width={getTaxInputWidth(length)}
                        onChange={event => handleTaxInput(event, name)}
                        disabled={identification === 'personal'}
                        defaultValue={''}
                      />
                      {einFromPrismic.length !== index + 1 && <StyledDivider />}
                    </Fragment>
                  ))}
                </Container>
                <Container>
                  <StyledCheckbox
                    {...register('includeBusinessPartner')}
                    label={
                      prismicAmbassadorEnroll.include_business_partner[0].text
                    }
                    value="includeBusinessPartner"
                    disabled={identification === 'personal'}
                    checked={includeBusinessPartner === true}
                    onChange={() => {
                      setIncludeBusinessPartner(prev => {
                        setValue('includeBusinessPartner', !prev)
                        return !prev
                      })
                    }}
                    onBlur={handleSaveDataOnBlur}
                  />
                </Container>
              </Container>
            </>
          ) : null}
          <SectionTitle>
            {prismicAmbassadorEnroll.personal_information[0].text}
          </SectionTitle>
          <>
            <Container align="start" margin="20px 0 0">
              <span
                style={{ fontSize: '12px' }}
              >{`*${prismicAmbassadorEnroll.required_fields[0].text}`}</span>
            </Container>
            <Container>
              {identification === 'business' && (
                <>
                  {errors.companyName && (
                    <ErrorLabel>{errors.companyName?.message}</ErrorLabel>
                  )}
                  <StyledInput
                    {...register('companyName')}
                    placeholder={prismicAmbassadorEnroll.business_name[0].text}
                    onBlur={handleSaveDataOnBlur}
                  />
                </>
              )}

              {errors.legalFirstName && (
                <ErrorLabel>{errors.legalFirstName?.message}</ErrorLabel>
              )}
              <StyledInput
                {...register('legalFirstName')}
                placeholder={`${prismicAmbassadorEnroll.legal_first_name[0].text}*`}
                onBlur={handleSaveDataOnBlur}
              />

              {errors.legalLastName && (
                <ErrorLabel>{errors.legalLastName?.message}</ErrorLabel>
              )}
              <StyledInput
                {...register('legalLastName')}
                placeholder={`${prismicAmbassadorEnroll.legal_last_name[0].text}`}
                onBlur={handleSaveDataOnBlur}
              />

              {errors.primaryPhoneNumber && (
                <ErrorLabel>{errors.primaryPhoneNumber?.message}</ErrorLabel>
              )}
              <StyledInput
                {...register('primaryPhoneNumber')}
                placeholder={`${prismicAddUpdateShippingPage.phone_number[0].text}*`}
                type="tel"
                onBlur={handleSaveDataOnBlur}
              />

              {errors.emailAddress && (
                <ErrorLabel>{errors.emailAddress?.message}</ErrorLabel>
              )}
              <StyledInput
                {...register('emailAddress')}
                placeholder={`${prismicProfilePage.email[0].text}*`}
                onBlur={handleSaveDataOnBlur}
              />
            </Container>

            <Container>
              {errors.dateBirth && (
                <ErrorLabel>{errors.dateBirth?.message}</ErrorLabel>
              )}
              {underAgeError && (
                <ErrorLabel>{prismicAmbassadorEnroll.must_be_18}</ErrorLabel>
              )}
              <Container direction="row" align="flex-start">
                <StyledInput
                  {...register('dateBirth')}
                  placeholder={`${prismicAmbassadorEnroll.birthdate[0].text}*`}
                  onBlur={handleSaveDataOnBlur}
                  onChange={e => {
                    setValue('dateBirth', e.target.value)
                    setUnderAgeError(false)
                  }}
                />
              </Container>
            </Container>
            {prismicAmbassadorEnroll.hide_tax_fields === false ? null : (
              <Container>
                <StyledCheckbox
                  {...register('addSpouseAsCoAmbassador')}
                  label={prismicAmbassadorEnroll.include_spouse_or_partner}
                  value="addSpouseAsCoAmbassador"
                  checked={addSpouseAsCoAmbassador === true}
                  onChange={() => {
                    setAddSpouseAsCoAmbassador(prev => {
                      setValue('addSpouseAsCoAmbassador', !prev)
                      return !prev
                    })
                  }}
                  disabled={identification === 'business'}
                  onBlur={handleSaveDataOnBlur}
                />
              </Container>
            )}
          </>
          <SectionTitle>{prismicAmbassadorEnroll.address[0].text}</SectionTitle>
          <>
            <Container margin="20px 0 0">
              {errors.address1 && (
                <ErrorLabel>{errors.address1?.message}</ErrorLabel>
              )}
              <StyledInput
                {...register('address1')}
                placeholder={`${prismicAddUpdateShippingPage.address_1[0].text}*`}
                onBlur={handleSaveDataOnBlur}
              />

              {errors.address2 && (
                <ErrorLabel>{errors.address2?.message}</ErrorLabel>
              )}
              <StyledInput
                {...register('address2')}
                placeholder={`${prismicAddUpdateShippingPage.address_2[0].text}`}
                onBlur={handleSaveDataOnBlur}
              />

              {errors.city && <ErrorLabel>{errors.city?.message}</ErrorLabel>}
              <StyledInput
                {...register('city')}
                placeholder={`${prismicAddUpdateShippingPage.city[0].text}*`}
                onBlur={handleSaveDataOnBlur}
              />
            </Container>

            <Container direction="row" justify="space-between" align="flex-end">
              <Container width="49%">
                {errors.state && (
                  <ErrorLabel>{errors.state?.message}</ErrorLabel>
                )}
                <StyledSelect
                  {...register('state')}
                  onBlur={handleSaveDataOnBlur}
                >
                  <option value="">
                    {prismicAddUpdateShippingPage.state[0].text}
                  </option>
                  {selectedCountry?.regions.map(region => (
                    <option key={region.regionId} value={region.regionCode}>
                      {region.regionName}
                    </option>
                  ))}
                </StyledSelect>
              </Container>

              <Container width="49%">
                {errors.zip && <ErrorLabel>{errors.zip?.message}</ErrorLabel>}
                <StyledInput
                  {...register('zip')}
                  placeholder={`${prismicAddUpdateShippingPage.zip_code[0].text}*`}
                  onBlur={handleSaveDataOnBlur}
                />
              </Container>
            </Container>
            <Container>
              <CountryDropdown
                {...register('country')}
                value={selectedCountry?.countryCode}
                onChange={e => {
                  setValue('country', e.target.value.toUpperCase())
                  onCountryChange(e)
                }}
              />
            </Container>
          </>
          <SectionTitle>
            {prismicAmbassadorEnroll.your_website[0].text}
          </SectionTitle>
          <Container margin="10px 0 0;">
            {prismicAmbassadorEnroll.slug_instruction[0].text}
          </Container>

          <Container margin="10px 0 0 0">
            {errors.slug && <ErrorLabel>{errors.slug?.message}</ErrorLabel>}
            {showSlugAvailableMessage ? (
              <>
                {isSlugAvailable ? (
                  <SuccessLabel>
                    {prismicAmbassadorEnroll.slug_is_available[0].text}
                  </SuccessLabel>
                ) : (
                  <ErrorLabel>
                    {prismicAmbassadorEnroll.slug_not_available[0].text}
                  </ErrorLabel>
                )}
              </>
            ) : (
              <Gap />
            )}
            <Container align="flex-end" direction="row">
              <StyledText>{`${prismicAmbassadorEnroll.qsciences_store[0].text}`}</StyledText>
              <StyledInput
                style={{ marginBottom: 0 }}
                {...register('slug')}
                value={slug}
                placeholder={`${prismicAmbassadorEnroll.sample_text[0].text}*`}
                onChange={e => {
                  setValue('slug', e.target.value)
                  setSlug(e.target.value)
                  trigger(slug.length > 1 && 'slug')
                  setShowSlugAvailableMessage(false)
                }}
                onBlur={() => {
                  validateSlug()
                  handleSaveDataOnBlur()
                }}
              />
              {loadingIsSlugAvailable && (
                <SlugLoadingSpinner
                  loading
                  size="large"
                  name="circle notched"
                />
              )}
            </Container>
          </Container>

          <SectionTitle>{prismicAmbassadorEnroll.tnc_s[0].text}</SectionTitle>
          <Container margin="10px 0 0;">
            <StyledCheckbox
              {...register('receiveText')}
              label={prismicCartPage.agree_receive_sms_and_email[0].text}
              value="receiveText"
              checked={receiveText === true}
              onChange={() => {
                setReceiveText(prev => {
                  setValue('receiveText', !prev)
                  return !prev
                })
              }}
              onBlur={handleSaveDataOnBlur}
            />
          </Container>
          {prismicAmbassadorEnroll.hide_tax_fields === false ? (
            <Container margin="10px 0 0;">
              {errors.send1099FormElectronically && (
                <ErrorLabel>
                  {errors.send1099FormElectronically?.message}
                </ErrorLabel>
              )}
              <Container justify="flex-start" direction="row">
                <StyledCheckbox
                  {...register('send1099FormElectronically')}
                  label={`${prismicAmbassadorEnroll.agree_1099[0].text}*`}
                  value="send1099FormElectronically"
                  checked={send1099FormElectronically === true}
                  onChange={() => {
                    setSend1099FormElectronically(prev => {
                      setValue('send1099FormElectronically', !prev)
                      return !prev
                    })
                  }}
                  onBlur={handleSaveDataOnBlur}
                />
                <ThemedPopup
                  content={prismicAmbassadorEnroll.popup_1099[0].text}
                  on="click"
                  onClose={() => setTen99PopupOpen(false)}
                  onOpen={() => setTen99PopupOpen(true)}
                  open={ten99PopupOpen}
                  position="bottom right"
                  hideOnScroll={true}
                  trigger={<InfoIcon src={infoIcon} />}
                />
              </Container>
            </Container>
          ) : null}

          <Container margin="10px 0 0;">
            {errors.areTermsAccepted && (
              <ErrorLabel>{errors.areTermsAccepted?.message}</ErrorLabel>
            )}
            <StyledCheckbox
              {...register('areTermsAccepted')}
              label={
                <label
                  dangerouslySetInnerHTML={{
                    __html: formatParagraph(
                      prismicCartPage.understand_and_agree[0]
                    ),
                  }}
                />
              }
              value="areTermsAccepted"
              checked={areTermsAccepted === true}
              onChange={() => {
                setAreTermsAccepted(prev => {
                  setValue('areTermsAccepted', !prev)
                  return !prev
                })
              }}
              onBlur={handleSaveDataOnBlur}
            />
          </Container>
          <Container style={{ marginTop: '20px' }}>
            <SubmitButton
              type="submit"
              disabled={loadingSubmission || underAgeError}
              loading={loadingSubmission}
            >
              {prismicAmbassadorEnroll.continue[0].text}
            </SubmitButton>
            {submissionErrorMessage !== null && (
              <ErrorLabel style={{ alignSelf: 'center', textAlign: 'center' }}>
                {submissionErrorMessage}
              </ErrorLabel>
            )}
          </Container>
        </StyledForm>
        <NextStepModal
          open={isNextStepModalOpen}
          setShowModal={setIsNextStepModalOpen}
        />
      </Wrapper>
    </>
  )
}

export default EnrollmentForm
